import React, {useEffect, useState} from "react";
import Dushboard from "../../layout/Dushboard";
import "./style.scss";
import funcAuth from "../../../controllers/auth";
import {useDispatch, useSelector} from "react-redux";
import {allCategoryAction} from "../../../redux/actions/allCategiryAction";
import {funcGet} from "../../../controllers";
import {allBenefitsAction} from "../../../redux/actions/allBenefitsAction";
import {TitlePage} from "../../fields";
import {NavLink} from "react-router-dom";
import {childrenAction} from "../../../redux/actions/childrenAction";

const Home = () => {
    const dispatch = useDispatch();
    const [parent, setParent] = useState([]);
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            getToken();
        }
    }, []);

    const getToken = async () => {
        const lastVisit = await funcAuth();
        dispatch(allBenefitsAction({token: lastVisit.token}));
        dispatch(allCategoryAction({token: lastVisit.token}));
        getChildren(lastVisit.token);
        getUsers(lastVisit.token)
    };

    const getChildren = async (token) => {
        const res = await funcGet("children", token);
        const data = await res.data;
        console.log('@@@@@ ', data);
        dispatch(childrenAction(data));
    };
    const getUsers = async (token) => {
        const res = await funcGet("users", token);
        const data = await res.data;

        setParent(data)
    };

    const renderChildren = parent.map((item, idx) => {

        return (
            <div key={item._id} className="children">
                <div className={'childrenBody'}>
                    <p className={'childrenBody__text'}>Имя {item.name ?? '--'}</p>
                    <p className={'childrenBody__text'}>{item.email}</p>
                </div>
                <NavLink to={`/children-profile/${item._id}`} className={'childrenBody__link'}> Перейти в профиль</NavLink>
            </div>
        );
    });

    return (
        <Dushboard>
            <div className="home-page">
                <TitlePage tag="h1" title="Добрый день Николай"/>
                <p>{parent.length} пользователей</p>
                <div className="list-children">{renderChildren}</div>
            </div>
        </Dushboard>
    );
};
export default Home;
