import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {TitlePage} from '../../fields';
import Dushboard from '../../layout/Dushboard';
import './style.scss';
import moment from 'moment';

function formatBirthday(birthday) {
    const day = String(birthday.day).padStart(2, '0');
    const month = String(birthday.month).padStart(2, '0');
    const year = birthday.year;

    return `${day}.${month}.${year}`;
}

const ChildrenProfile = () => {
    let {id} = useParams();

    const {items} = useSelector((state) => state.childrens);

  const currentChildren = items.find(item => item.parentLink === id)

    const birthday = currentChildren && formatBirthday(JSON.parse(currentChildren?.birthday));
    const achievements = currentChildren && JSON.parse(currentChildren.achievements);
    const dataCreate = currentChildren && currentChildren.dataCreate.split('T')[0];
    const doneLessons = achievements && achievements.coursesData.filter(item => item.done > 0);

   console.log('achievements', currentChildren);
    return (
        <Dushboard>
            {
                currentChildren
                ? (
                        <div className="col-12">
                            <TitlePage title={currentChildren?.name}/>
                            <div className="children-profile">
                                <p className="children-profile__text"><span
                                    className="children-profile__span">Имя ребенка:</span> {currentChildren?.name}</p>
                                <p className="children-profile__text">
                                    <span className="children-profile__span">День рождения ребенка:</span> {birthday}
                                </p>
                                 <p className="children-profile__text">
                                    <span className="children-profile__span">Дата создания профиля:</span> {dataCreate}
                                </p>

                                <p className="children-profile__text">
                                    <span
                                    className="children-profile__span">Выполнено норм:</span>
                                    <ul className={'norm'}>
                                        {achievements.normDone.map(i =>
                                            <li className={'norm__li'} key={i.id}>
                                                <span className={'norm__text'}>{i.category}</span>
                                                <span className={'norm__text_grey'}>дата {moment.unix(i.timestamp).format("DD.MM.YYYY HH:mm:ss")}</span>
                                            </li>)}
                                    </ul>

                                </p>
                                <p className="children-profile__text"><span
                                    className="children-profile__span">Ведет приложение:</span> {currentChildren?.parent}</p>

                            </div>
                        </div>
                    ) : null
            }

        </Dushboard>
    );
};

export default ChildrenProfile;
